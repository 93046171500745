<template>
  <v-card class="secondary card-radius1" max-width="985" flat min-height="500">
    <v-card-text class="">
      <v-row class="mt-4 justify-center">
        <v-slide-group v-model="model" center-active show-arrows mandatory class="mb-4">
          <v-slide-item
            v-for="(item, i) in packagePlans"
            :key="i"
            v-slot="{ active, toggle }"
          >
            <v-row class="my-6" @click="toggle" >
              <v-card
                class="card-radius white mx-4"
                max-width="160"
                min-width="160"
                :elevation="active ? '12' : '6'"
                @click="getPackageDetails(item)"
                @mouseenter="getPackageDetails(item)"
                min-height="150"
                max-height="150"
              >
                <v-img
                  :src="baseUrl + item.image"
                  class="card-radius"
                  :class="{'mt-n3':active}"
                  :min-height="active ? '180' : '150'"
                  :max-height="active ? '180' : '150'"
                />
                <v-chip class="white black--text mt-n12 max-width-chip">
                  {{ $i18n.locale === "en" ? item.titleEn : item.titleAr }}
                </v-chip>
              </v-card>
            </v-row>
          </v-slide-item>
        </v-slide-group>
      </v-row>
      <v-row class="justify-center mt-1">
        <h1 class="subtitle-1">
          {{
            $i18n.locale === "en"
              ? chosenPlan.titleEn || "Healthy Plan"
              : chosenPlan.titleAr || "Healthy Plan"
          }}
        </h1>
      </v-row>
      <v-row class="justify-center mt-n2">
        <v-card
          :max-width="$vuetify.breakpoint.mobile ? '250' : '455'"
          :min-width="$vuetify.breakpoint.mobile ? '90%' : '455'"
          
          min-height="100"
          flat
          color="#FAFAFA"
          class="mt-5 card-radius"
        >
          <v-card-text>
            <small>
              {{
                $i18n.locale === "en"
                  ? chosenPlan.descriptionEn
                  : chosenPlan.descriptionAr
              }}
            </small>
          </v-card-text>
        </v-card>
      </v-row>
    </v-card-text>
    <v-layout justify-center class="mt-3">
      <v-btn
        depressed
        rounded
        color="primary custom-font"
        class="black--text font-weight-light mt-2"
        :width="$vuetify.breakpoint.xs ? 220 : 260"
        type="submit"
        @click="$router.push('/menu')"
        >{{ $t("menu.proceed") }}
        <v-icon right> mdi-arrow-right </v-icon>
      </v-btn>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      btn_loading: false,
      model: null,
      packagePlans: [],
      packageId: null,
      baseUrl: process.env.VUE_APP_BASE_URL,
      chosenPlan: {},
    };
  },
  apollo: {
    getAllPackages() {
      return {
        query: require("@/package/api/queries/get_all_packages.graphql"),
        result({ data }) {
          this.packagePlans = data.getAllPackages.packages;
          if (this.packagePlans.length)
            this.getPackageDetails(this.packagePlans[0]);
        },
      };
    },
  },
  methods: {
    getPackageDetails(item) {
      this.packageId = item._id;
      this.$apollo
        .query({
          query: require("@/package/api/queries/single_package.graphql"),
          variables: {
            packageId: this.packageId,
          },
        })
        .then((data) => {
          this.chosenPlan = data.data.getPackageById;
          this.$store.commit("SELECTED_PACKAGE", this.chosenPlan);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.max-width-chip {
  min-width: 160px;
  max-width: 160px;
}
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
.custom-table {
  min-width: 250px;
}
.card-radius1 {
  border-radius: 110px !important;
}
</style>